@import "../../../../style/variables.scss";
@import "../../../../style/mixins.scss";

.pageNotFoundContainer {
	background: url("https://cdn.deepintent.com/advertiser-ui/assets/page_not_found.jpg");
	/* Full height */
	height: 100%;
	width: 100%;
	position: fixed;
	/* Center and scale the image nicely */
	background-repeat: no-repeat;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
}

.diLogoContainer {
	width: 140px;
	margin: 50px 0 0 60px;

	> svg {
		@include link();
	}
}

.pageNotFoundTextContainer {
	margin-left: 280px;
	margin-top: 160px;

	.headerText {
		@include fontSize(48px);
		color: $color-nileBlue;
		font-weight: $font-bold;
		line-height: 1.21;
	}
	.detailText {
		line-height: 1.75;
		color: $color-nileBlue;
	}
	.addressText {
		color: $color-rustOrange;
	}
	.helpCenterText {
		line-height: 1.75;
		color: $color-nileBlue;
		margin-bottom: 20px;

		.supportIcon {
			vertical-align: text-bottom;
		}
	}
}

.pageNotFoundDescContainer {
	width: 600px;
	margin-top: 20px;
}
