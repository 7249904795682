@import "@deepintent/react-component-lib-core/style/variables";
@import "@deepintent/react-component-lib-core/style/misc";

$font-family: "Titillium Web", sans-serif;
$app-side-bar-partial-collapsed-width: 48px; // width and gutter
$app-side-bar-partial-expanded-width: 196px; // width and gutter
$header-height: 56px;
$sidebar-width: 48px;

#border-none {
	border: none;
	box-shadow: none;
}
