@import "mixins";

html {
	font-size: $html-font-size;
}

body {
	font-family: $font-family, Helvetica, Arial, sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	@include normal-text;
}

h1,
h2 {
	font-family: $font-family;
}
