@import "../../../style/mixins";

.homeContainerRow {
	flex-wrap: nowrap;
	height: 100%;

	.childrenWrapper {
		position: relative;
		z-index: 90;
		margin-left: 30px;
		margin-right: 24px;
		width: 100%;
	}
}

.appContainer {
	position: absolute;
	top: $header-height;
	width: 100%;
	bottom: 0;
	overflow: hidden;
	@include appBackground;
}
