@import "../../../style/variables";

.sidebar-root {
	overflow: hidden !important;
}

.sidebar-wrapper {
	z-index: 100;
	padding-left: 10px;
	width: $app-side-bar-partial-collapsed-width;
	flex: 0 0 $sidebar-width;

	.menu-item-wrapper {
		width: 100%;
	}
}

.side-panel {
	top: $header-height;
	bottom: 0;
	width: $app-side-bar-partial-expanded-width;
	position: fixed;
	&.side-panel-collapsed {
		width: $app-side-bar-partial-collapsed-width;
	}
}
